import { EventsType } from "react-idle-timer";

export const IDLE_EVENTS: EventsType[] = [
  "keydown",
  "wheel",
  "DOMMouseScroll",
  "mousewheel",
  "mousedown",
  "touchstart",
  "touchmove",
  "MSPointerDown",
  "MSPointerMove",
  "visibilitychange",
];
