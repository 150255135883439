import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";
import { useIdleTimer } from "react-idle-timer";
import { MINUTE } from "shared/lib/constants/timeConstants";
import noop from "shared/lib/utils/noop";
import { IDLE_EVENTS } from "../constants/IdleEvents";

interface TakeTimeoutParams {
  /**
   * The number of milliseconds until the activity times out.
   */
  timeoutMillis: number;

  disabled: boolean;

  /**
   * The function to call an amount of time (e.g. 5 minutes) before the timeout
   */
  onWarning(): void;

  /**
   * The function to call when the timeout is hit.
   */
  onTimeout(): void;
}

/**
 * The number of minutes before the hard timeout to show a warning
 */
const timeoutWarningMinutes = 5 * MINUTE;

export interface TakeTimeoutContextValues {
  isTakeTimeoutActive: boolean;
  onChangeTakeTimeoutParams(
    params: TakeTimeoutParams | null,
  ): { cleanup: () => void };
}

export const TakeTimeoutContext = createContext<TakeTimeoutContextValues | null>(
  null,
);

export const TakeTimeoutContextProvider = ({ children }: PropsWithChildren) => {
  const [
    takeTimeoutParams,
    setTakeTimeoutParams,
  ] = useState<TakeTimeoutParams | null>(null);

  const {
    timeoutMillis,
    disabled,
    onWarning,
    onTimeout,
  } = takeTimeoutParams ?? {
    timeoutMillis: null,
    disabled: true,
    onWarning: noop,
    onTimeout: noop,
  };

  // In case the timeout millis is < 6 minutes, make sure its at least 6 minutes.
  const realTimeout = Math.max(
    timeoutMillis === null ? 0 : timeoutMillis,
    6 * MINUTE,
  );

  useIdleTimer({
    // timeoutWarningMinutes before the hard timeout, trigger a warning
    timeout: Math.max(0, realTimeout - timeoutWarningMinutes),
    onIdle: onWarning,
    debounce: 500,
    events: IDLE_EVENTS,
    disabled,
  });

  useIdleTimer({
    timeout: realTimeout,
    onIdle: onTimeout,
    debounce: 500,
    events: IDLE_EVENTS,
    disabled,
  });

  const handleChangeTakeTimeoutParams = useCallback(
    (params: TakeTimeoutParams) => {
      setTakeTimeoutParams(params);
      return { cleanup: () => setTakeTimeoutParams(null) };
    },
    [],
  );

  return (
    <TakeTimeoutContext.Provider
      value={{
        isTakeTimeoutActive: takeTimeoutParams !== null,
        onChangeTakeTimeoutParams: handleChangeTakeTimeoutParams,
      }}
    >
      {children}
    </TakeTimeoutContext.Provider>
  );
};

export function useTakeTimeoutContext(): TakeTimeoutContextValues {
  const contextValue = useContext(TakeTimeoutContext);

  if (!contextValue) {
    throw new Error(
      "Hook useTakeTimeoutContext must be a child of TakeTimeoutContextProvider",
    );
  }

  return contextValue;
}
