export enum LogoutEvent {
  /**
   * The user clicked the logout button.
   */
  USER_INITIATED = "user-initiated",
  /**
   * The user session expired and the auto-logout occurred
   */
  AUTO_LOGOUT = "auto-logout",
  /**
   * The user session became invalid (expired token).
   * Note depending on timing, this can be the same as AUTO_LOGOUT
   */
  SESSION_TOKEN_INVALIDATED = "session-invalid"
}

export const LOGOUT_EVENTS = Object.values(LogoutEvent);
