import { useCallback, useMemo, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useQuery } from "@tanstack/react-query";
import { MINUTE } from "shared/lib/constants/timeConstants";
import { LogoutEvent } from "shared/lib/constants/auth/LogoutEvent";
import getSession from "../api/auth/getSession";
import logout from "../api/auth/logout";
import { useWorkerTimeout } from "./useWorkerTimeout";
import { refreshSession } from "../api/auth/refreshSession";
import { useWorkerInterval } from "./useWorkerInterval";
import { SESSION_REFRESH_INTERVAL } from "../env";
import { useTakeTimeoutContext } from "../contexts/TakeTimeoutContext";
import { IDLE_EVENTS } from "../constants/IdleEvents";

const INACTIVITY_WARNING_THRESHOLD_MAX = Math.max(
  2 * MINUTE,
  SESSION_REFRESH_INTERVAL - 10 * MINUTE,
);
const INACTIVITY_WARNING_THRESHOLD_MIN = MINUTE;

function calculateIdleWarningThreshold(expirationMillis: number) {
  // This is mainly during QA testing, pop the warning when half the time is left
  if (expirationMillis < 20 * MINUTE) {
    return Math.max(INACTIVITY_WARNING_THRESHOLD_MIN, expirationMillis / 2);
  }
  return Math.max(
    INACTIVITY_WARNING_THRESHOLD_MIN,
    expirationMillis - INACTIVITY_WARNING_THRESHOLD_MAX,
  );
}

export function useSession() {
  const [idle, setIdle] = useState(false);
  const { isTakeTimeoutActive } = useTakeTimeoutContext();

  const {
    data: { session, expiresAt } = { session: null, expiresAt: null },
    refetch,
    isLoading,
    remove,
  } = useQuery({
    queryKey: [getSession.queryKey],
    queryFn: getSession,
  });

  const reloadSession = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const cleanupSession = useCallback(() => {
    remove();
    reloadSession();
  }, [reloadSession, remove]);

  const { expiresIn, idleTimeout } = useMemo(() => {
    // If its already expired, the diff between that time and now will be less than 0
    const expires = expiresAt ? Math.max(0, expiresAt - Date.now()) : 0;
    return {
      expiresIn: expires,
      idleTimeout: calculateIdleWarningThreshold(expires),
    };
  }, [expiresAt]);

  const autoLogout = useCallback(async () => {
    if (expiresAt === null || !idle || isTakeTimeoutActive) {
      return;
    }
    await logout(LogoutEvent.AUTO_LOGOUT);
    cleanupSession();
  }, [cleanupSession, expiresAt, idle, isTakeTimeoutActive]);

  const refreshAndReloadSession = useCallback(async () => {
    if (idle || !expiresAt || !session) {
      return;
    }
    await refreshSession();
    await refetch();
  }, [refetch, expiresAt, idle, session]);

  const onIdle = useCallback(() => {
    setIdle(true);
  }, []);

  const onAction = useCallback(() => {
    setIdle(false);
    refreshAndReloadSession();
  }, [refreshAndReloadSession]);

  useIdleTimer({
    timeout: idleTimeout,
    onIdle,
    onAction,
    events: IDLE_EVENTS,
    disabled: isTakeTimeoutActive,
  });

  useWorkerTimeout(
    autoLogout,
    expiresIn,
    expiresAt === null || !idle || isTakeTimeoutActive,
  );

  useWorkerInterval(
    refreshAndReloadSession,
    SESSION_REFRESH_INTERVAL,
    idle || !expiresAt || !session,
  );

  return {
    sessionLoading: isLoading,
    session,
    cleanupSession,
    reloadSession,
    idle,
  };
}
