import React, {
  useEffect,
  PropsWithChildren,
  FC,
  StrictMode,
  lazy,
  Suspense,
} from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import usePrintDetect from "./hooks/usePrintDetect";
import { useSession } from "./hooks/useSession";
import { useAxiosStatusInterceptor } from "./hooks/useAxiosStatusInterceptor";
import { useHealthCheck } from "./hooks/health/useHealthCheck";
import HealthCheckModal from "./components/HealthCheckModal/HealthCheckModal";
import SpinnerOverlay from "./components/SpinnerOverlay";
import { GlobalAppContextProvider } from "./contexts/GlobalAppContext";
import { TakeTimeoutContextProvider } from "./contexts/TakeTimeoutContext";

const UnauthenticatedRoot = lazy(() => import("./roots/UnauthenticatedRoot"));
const AuthenticatedRoot = lazy(() => import("./roots/AuthenticatedRoot"));

const BaseApp: FC<PropsWithChildren> = () => {
  const {
    session,
    sessionLoading,
    cleanupSession,
    reloadSession,
    idle,
  } = useSession();

  const { healthCheckResult, onRefresh } = useHealthCheck();

  useAxiosStatusInterceptor({ onUnauthorized: reloadSession });

  const isPrint = usePrintDetect();
  useEffect(() => {
    if (isPrint) {
      /*
        Observation: *something* is adding "overflow: hidden auto;" which prevent all the pages from printing.
        This appears to only be happening in dev.
        This is needed to temporarily remove the "cssText" while in print so it can be developed.
       */
      const previousBodyStyle = document.body.style;
      document.body.style.cssText = "";
      return () => {
        document.body.style.cssText = previousBodyStyle.cssText;
      };
    }
    return () => {
      // Intentional
    };
  }, [isPrint]);

  if (sessionLoading) {
    return null;
  }

  return (
    <GlobalAppContextProvider>
      {session ? (
        <Suspense fallback={<SpinnerOverlay />}>
          <AuthenticatedRoot
            session={session}
            reload={reloadSession}
            cleanupSession={cleanupSession}
            idle={idle}
          />
        </Suspense>
      ) : (
        <Suspense fallback={<SpinnerOverlay />}>
          <UnauthenticatedRoot reload={reloadSession} />
        </Suspense>
      )}
      <HealthCheckModal
        healthCheckResult={healthCheckResult}
        onRefresh={onRefresh}
      />
    </GlobalAppContextProvider>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = () => (
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <TakeTimeoutContextProvider>
        <BaseApp />
        <ReactQueryDevtools />
      </TakeTimeoutContextProvider>
    </QueryClientProvider>
  </StrictMode>
);
